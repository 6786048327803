import React, { Component } from "react";
import { Link, withRouter } from "react-router";
import "./Header.scss";
import mainlogo from "../../assets/images/logo1.png";
import { Row, Col, Button } from "antd";
import { ArrowUpRight } from "feather-icons-react";

class Header extends React.Component {
  componentDidMount() {
    window.onscroll = function () {
      myFunction();
    };

    var header = document.getElementById("main-navigation");
    var sticky = header.offsetTop;

    function myFunction() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
    //Onscroll sticky Header function ends here
  }
  searchtext = () => {
    var element = document.getElementById("search");
    element.classList.add("open");
    // var search = document.getElementsByClassName("searchfocus");
    // search.focus()
  };
  togglemenu = () => {
    // const element = document.getElementById('center-menu')
    // // document.getElementById('center-menu').style.display='block';
    // console.log( 'element')
    // if(element.style.display === 'block'){
    // 	element.style.display ='none'
    // } else{
    // 	element.style.display === 'block'
    // }

    const x = document.getElementById("center-menu");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  };

  closesearchbar = () => {
    var element = document.getElementById("search");
    element.classList.remove("open");
  };
  render() {
    const { location } = this.props;
    const routeArr = [
      "/",
      "/about",
      "/products",
      "/services",
      "/solutions",
      "/contact",
      "/disclaimer",
      "/privacy-policy",
      "/case-study",
      "/blog",
      "/faq",
      "/blog-details",
    ];
    return (
      <div
        className={
          routeArr.indexOf(location.pathname) >= 0
            ? "main-navigation"
            : "main-navigation inner-nav"
        }
        id="main-navigation"
      >
        <div className="navigation-style">
          <nav>
            {/* <!--Logo --> */}
            <div className="brand">
              <Link to="/">
                <img src={mainlogo} alt="Document IT" className="white-logo" />
                <img src={mainlogo} alt="Document IT" className="dark-logo" />
              </Link>
            </div>
            <div className="mobile-menu">
              <i className="fa fa-bars" onClick={this.togglemenu}></i>
            </div>
            {/* <!--Navigation starts--> */}
            <div className="center-menu" id="center-menu">
              <ul>
                <li className={location.pathname === "/" ? "active" : ""}>
                  <Link to="/">Home</Link>
                </li>
                <li
                  className={
                    location.pathname === "/stakeholders" ? "active" : ""
                  }
                >
                  <Link to="stakeholders">Stakeholders</Link>
                </li>
                <li className={location.pathname === "/tools" ? "active" : ""}>
                  <Link to="tools">Tools</Link>
                </li>
                <li
                  className={location.pathname === "/services" ? "active" : ""}
                >
                  <Link to="services">Workday® services</Link>
                </li>
                <li className={location.pathname === "/about" ? "active" : ""}>
                  <Link to="about">About Us</Link>
                </li>
                <li className={location.pathname === "/career" ? "active" : ""}>
                  <Link to="career">Career</Link>
                </li>
                <li
                  className={location.pathname === "/contact" ? "active" : ""}
                >
                  <Link to="contact">Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className="right-action-button">
              <Button
                type="primary"
                icon={<ArrowUpRight size={15} />}
                iconPosition="end"
                color="primary"
                href="/#/contact"
              >
                Get Started
              </Button>
            </div>
            {/* <!--Navigation ends--> */}
          </nav>
        </div>
      </div>
    );
  }
}
export default withRouter(Header);
