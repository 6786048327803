import React from "react";
import { connect } from "react-redux";
import Layout from "../Layout";
import contact from "../../assets/images/contact-us.png";
import locations from "../../assets/images/locations.png";
import { Row, Col, notification } from "antd";
import { createContactus } from "../../actions/contactusactions";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import { CallCalling, Direct, DirectboxDefault } from "iconsax-react";

class Contact extends React.Component {
  state = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    captcha: false,
  };

  onChangeText = (value, type) => {
    this.setState({ [type]: value });
  };
  onChangeCaptcha = (value) => {
    this.setState({ captcha: true });
  };

  onClear = () => {
    this.setState({ name: "", email: "", phone: "", subject: "", message: "" });
  };

  onSubmit = () => {
    const { name, email, phone, subject, message, captcha } = this.state;

    if (
      name !== "" &&
      email !== "" &&
      phone !== "" &&
      subject !== "" &&
      message !== ""
    ) {
      if (!captcha) {
        alert("Please confirm your not a robot.");
        return true;
      }
      createContactus({ name, email, phone, subject, message }).then((data) => {
        if (data && !data.error) {
          this.onClear();
          notification.open({
            message: "Success",
            description: "Thank you! we will reach you shortly",
          });
        } else {
          alert("Contact us failed! Please try again");
        }
      });
    } else {
      alert("Please fill all the fields");
    }
  };

  render() {
    const ContactDetails = [
      {
        title: "Phone Number",
        icon: <CallCalling />,
        number: "+41617178787",
        link: "tel:+41617178787",
      },
      {
        title: "Fax",
        icon: <DirectboxDefault />,
        number: "+41617178788",
        link: "tel:+41617178788",
      },
      {
        title: "Email Address",
        icon: <Direct />,
        number: "info@documentit.com",
        link: "mailto:info@documentit.com",
      },
    ];
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <Breadcrumbs
            {...this.props}
            navigatons={[
              {
                classname: "breadcrum contact-us",
                title: "Contact Us",
                description:
                  "Thank you for your interest. Please fill out the form below to inquire about our work ",
              },
            ]}
          />
          {/* <!--Main Slider section ends--> */}

          <section className="contact-support spacing-top bg-light-gray">
            <div className="container">
              <Row
                gutter={[
                  { xs: 0, sm: 16, md: 22, lg: 22 },
                  { xs: 0, sm: 16, md: 22, lg: 22 },
                ]}
                className="pb-3 "
              >
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                  data-aos="fade-up"
                >
                  <div
                    className="highlight-title contact-card text-left mb-2"
                    data-aos="fade-up"
                  >
                    <h4 className="text-primary">Contact Support</h4>
                    <p className="mb-2">
                      Expect a response from our Support Team within 24 hours.
                    </p>
                    <ul>
                      {ContactDetails.map((v, index) => (
                        <li key={index}>
                          <a href={v.link}>
                            <span>{v.icon}</span> {v.number}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                  data-aos="fade-up"
                >
                  <div
                    className="highlight-title contact-card text-left mb-2"
                    data-aos="fade-up"
                  >
                    <h4 className="text-primary">Office Locations</h4>

                    <h6 className="text-black">Document IT LLC</h6>
                    <p className="mb-0">
                      <span className="d-block">Christoph Merian-Ring 11</span>
                      <span className="d-block">CH-4153 Reinach BL</span>
                    </p>
                    <div className="border-bottom pb-2 mb-2 mt-2" />
                    <h6 className="text-black">
                      DOCIT SYRL INDIA (OPC) PRIVATE LIMITED
                    </h6>
                    <p className="mb-0">
                      <span className="d-block">
                        Brigade Triumph, 2nd Floor,
                      </span>
                      <span className="d-block">
                        Dasarahalli Main Rd, Sector B,
                      </span>
                      <span className="d-block">Hebbal, Bengaluru 560092</span>
                    </p>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                  data-aos="fade-up"
                >
                  <div
                    className="highlight-title contact-card text-left mb-2"
                    data-aos="fade-up"
                  >
                    <h4 className="mb-3 text-primary">Get in touch</h4>
                    <p>
                      Have a specific inquiry? Here's a few ways you can get in
                      touch with us.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </section>

          {/* <!--contact-us-page starts--> */}
          <div className="contact-us-page bg-white">
            <Row justify="center">
              <Col span={20}>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <div className="heading">
                      <h3>We'd Love To Hear from you</h3>
                      <p>
                        Whether you have a question about features, trials,
                        pricing, need a demo, or anything, our team is ready to
                        answer all your questions
                      </p>
                    </div>

                    <Row justify="center" gutter={16}>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                      >
                        <img
                          src={contact}
                          alt="contact Document IT"
                          title="contact Document IT"
                        />
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                      >
                        <div className="form-fields">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            required
                            placeholder="Full Name"
                            value={this.state.name}
                            onChange={(e) =>
                              this.onChangeText(e.target.value, "name")
                            }
                          />
                        </div>
                        <div className="form-fields">
                          <input
                            type="email"
                            name="email"
                            id="email"
                            required
                            placeholder="Email ID"
                            value={this.state.email}
                            onChange={(e) =>
                              this.onChangeText(e.target.value, "email")
                            }
                          />
                        </div>
                        <div className="form-fields">
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            required
                            placeholder="Phone Number"
                            value={this.state.phone}
                            onChange={(e) =>
                              this.onChangeText(e.target.value, "phone")
                            }
                          />
                        </div>
                        <div className="form-fields">
                          <input
                            type="text"
                            name="subject"
                            id="subject"
                            required
                            placeholder="Subject"
                            value={this.state.subject}
                            onChange={(e) =>
                              this.onChangeText(e.target.value, "subject")
                            }
                          />
                        </div>
                        <div className="form-fields">
                          <textarea
                            id="message"
                            required
                            placeholder="Message..."
                            value={this.state.message}
                            onChange={(e) =>
                              this.onChangeText(e.target.value, "message")
                            }
                          ></textarea>
                        </div>
                        {/* <div className="form-fields">
                          <ReCAPTCHA
                            sitekey="6Ld2BoQaAAAAAGRhgxfUZeIXZUj9ubT9Q6734N9K"
                            // sitekey="6LcslYQaAAAAAHJYwRIpQ7q-7uVi26aOK10PYsGn" localhost for testing
                            onChange={this.onChangeCaptcha}
                          />
                        </div> */}
                        <div className="form-fields">
                          <input
                            className="btn btn-primary"
                            type="button"
                            value="Submit"
                            onClick={this.onSubmit}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {/* <!--contact-us-page ends--> */}

          {/* <!--locations starts--> */}
          <div className="locations">
            <div className="container">
              <h2>Office Locations</h2>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <img src={locations} alt="Document IT locations" />
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default connect()(Contact);
