import React from "react";
import { connect } from "react-redux";
import {
  changeadminloginform,
  validateadmin,
} from "../src/actions/adminloginactions";
import documentitlogin from "./assets/images/login.svg";
import documentitlogo from "./assets/images/logo1.png";

class Login extends React.Component {
  changefield = (obj) => {
    console.log(obj, "obj");
    const { dispatch } = this.props;
    dispatch(changeadminloginform(obj));
  };
  submitlogincredentials = (event) => {
    event.preventDefault();
    const { dispatch, admin } = this.props;
    dispatch(validateadmin(admin)).then((response) => {
      console.log(response, "response");
      if (!response) {
        alert("Network Error");
      } else if (response.error) {
        alert(response.message);
      } else if (!response.error) {
        if (response.result) {
          this.props.router.push("/addblog");
        }
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="login">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="content">
                  <div className="center-content">
                    <div className="login-sections">
                      <div className="left">
                        <div className="details">
                          <img
                            src={documentitlogin}
                            alt="Document IT web admin login"
                          />
                        </div>
                      </div>
                      <div className="right">
                        <div className="details">
                          <div className="heading">
                            <img
                              src={documentitlogo}
                              alt="Document IT web admin login"
                            />
                            <h1>Web Admin Login</h1>
                          </div>
                          <form>
                            <div className="form-fields">
                              <input
                                type="text"
                                Placeholder="Username/Email"
                                onChange={(e) =>
                                  this.changefield({
                                    key: "username",
                                    value: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="form-fields">
                              <input
                                type="password"
                                Placeholder="Password"
                                onChange={(e) =>
                                  this.changefield({
                                    key: "password",
                                    value: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="form-fields">
                              <button
                                type="submit"
                                onClick={this.submitlogincredentials}
                              >
                                Login
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  admin: state.AdminReducer.adminform,
});

export default connect(mapStateToProps)(Login);
