import React from "react";
import { connect } from "react-redux";
import { Row, Col, notification } from "antd";
import { createContactus } from "../../actions/contactusactions";

class ContactCTA extends React.Component {
  state = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    captcha: false,
  };

  onChangeText = (value, type) => {
    this.setState({ [type]: value });
  };
  onChangeCaptcha = (value) => {
    this.setState({ captcha: true });
  };

  onClear = () => {
    this.setState({ name: "", email: "", phone: "", subject: "", message: "" });
  };

  onSubmit = () => {
    const { name, email, phone, subject, message, captcha } = this.state;

    if (
      name !== "" &&
      email !== "" &&
      phone !== "" &&
      subject !== "" &&
      message !== ""
    ) {
      if (!captcha) {
        alert("Please confirm your not a robot.");
        return true;
      }
      createContactus({ name, email, phone, subject, message }).then((data) => {
        if (data && !data.error) {
          this.onClear();
          notification.open({
            message: "Success",
            description: "Thank you! we will reach you shortly",
          });
        } else {
          alert("Contact us failed! Please try again");
        }
      });
    } else {
      alert("Please fill all the fields");
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* <!--contact-us-page starts--> */}
        <div className="contact-us-page bg-white contact-us-cta">
          <Row justify="center">
            <Col span={20}>
              <Row justify="center" gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <Row justify="center" gutter={16}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    />
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <span className="d-block mb-2 font-4 text-uppercase text-primary">
                        CONNECT WITH US For A Demo
                      </span>
                      <p className="text-gray">
                        If you need to speak to us about a general query fill in
                        the form below and we will call you back within the same
                        working day.
                      </p>
                      <h4 className="mb-2">How can we help?</h4>

                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 form-fields">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            required
                            placeholder="Full Name"
                            value={this.state.name}
                            onChange={(e) =>
                              this.onChangeText(e.target.value, "name")
                            }
                          />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 form-fields">
                          <input
                            type="email"
                            name="email"
                            id="email"
                            required
                            placeholder="Work Email"
                            value={this.state.email}
                            onChange={(e) =>
                              this.onChangeText(e.target.value, "email")
                            }
                          />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 form-fields">
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            required
                            placeholder="Phone Number"
                            value={this.state.phone}
                            onChange={(e) =>
                              this.onChangeText(e.target.value, "phone")
                            }
                          />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 form-fields">
                          <input
                            type="text"
                            name="subject"
                            id="subject"
                            required
                            placeholder="Subject"
                            value={this.state.subject}
                            onChange={(e) =>
                              this.onChangeText(e.target.value, "subject")
                            }
                          />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 form-fields">
                          <textarea
                            id="message"
                            required
                            placeholder="Message..."
                            value={this.state.message}
                            onChange={(e) =>
                              this.onChangeText(e.target.value, "message")
                            }
                          ></textarea>
                        </div>
                        {/* <div className="col-sm-12 col-md-12 col-lg-12 form-fields">
                          <ReCAPTCHA
                            sitekey="6Ld2BoQaAAAAAGRhgxfUZeIXZUj9ubT9Q6734N9K"
                            // sitekey="6LcslYQaAAAAAHJYwRIpQ7q-7uVi26aOK10PYsGn" localhost for testing
                            onChange={this.onChangeCaptcha}
                          />
                        </div> */}
                        <div className="col-sm-12 col-md-12 col-lg-12 form-fields">
                          <input
                            className="btn btn-primary"
                            type="button"
                            value="Send Message"
                            onClick={this.onSubmit}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        {/* <!--contact-us-page ends--> */}
      </React.Fragment>
    );
  }
}

export default connect()(ContactCTA);
