import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { Table, Row, Input, Col, Menu, Modal, Button, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
const { TextArea } = Input;
const moreactions = (
  <Menu>
    <Menu.Item key="0">
      <Link to="#">View</Link>
    </Menu.Item>
    <Menu.Item key="1">
      <a href="#">Delete</a>
    </Menu.Item>
  </Menu>
);

// table starts here
const columns = [
  {
    title: "Name",
    dataIndex: "name",
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ["descend"],
  },
  {
    title: "Location",
    dataIndex: "location",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.location - b.location,
  },
  {
    title: "Testimonials",
    dataIndex: "testimonials",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.testimonials - b.testimonials,
  },
  {
    title: "Action",
    dataIndex: "",
    key: "x",
    render: () => (
      <Dropdown overlay={moreactions} trigger={["click"]} arrow>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          More Actions <DownOutlined />
        </a>
      </Dropdown>
    ),
  },
];

const data = [
  {
    key: "1",
    name: "Lorge Pharma company",
    location: "London",
    testimonials: "sample testimonials content",
  },
  {
    key: "2",
    name: "Lorge Pharma company",
    location: "London",
    testimonials: "sample testimonials content",
  },
  {
    key: "3",
    name: "Lorge Pharma company",
    location: "USA",
    testimonials: "sample testimonials content",
  },
];

function onChange(pagination, filters, sorter, extra) {
  console.log("params", pagination, filters, sorter, extra);
}
// table ends here
class AddTestimonials extends React.Component {
  // model popup starts
  state = {
    loading: false,
    visible: false,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
  //   model popup ends here

  render() {
    const { visible, loading } = this.state;
    return (
      <div>
        <h1>Testimonials</h1>

        <div className="action-button">
          <Button
            type="primary"
            className="btn btn-primary"
            onClick={this.showModal}
          >
            Open Modal with customized footer
          </Button>
        </div>

        <div className="card-design">
          <Table
            columns={columns}
            dataSource={data}
            onChange={onChange}
            rowSelection
          />
        </div>

        {/* model for view details */}
        <Modal
          visible={visible}
          title="View Details"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={800}
          footer={false}
        >
          <div className="view-contact-details">
            <Row justify="center" gutter={16}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <div className="form-fields">
                  <span>Name</span>
                  <p>Large Pharma Company USA</p>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <div className="form-fields">
                  <span>Location</span>
                  <p>USA</p>
                </div>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div className="form-fields">
                  <span>Message</span>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="enter-testimonials">
            <Row justify="center" gutter={16}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <div className="form-fields">
                  <label>Name</label>
                  <Input placeholder="Basic usage" />
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <div className="form-fields">
                  <label>Location</label>
                  <Input placeholder="Basic usage" />
                </div>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div className="form-fields">
                  <label>Message</label>
                  <TextArea showCount maxLength={500} />
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div className="actions-fields">
                  <Button type="primary" className="btn btn-primary">
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
        {/* model for view details ends here */}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(AddTestimonials);
