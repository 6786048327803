import React from "react";
import renderHTML from "react-render-html";
import { connect } from "react-redux";
import CheckBoxTable from "./checkboxtable";
import "react-accessible-accordion/dist/fancy-example.css";
import {
  fetchfaqs,
  createfaq,
  emptyfaqform,
  editfaq,
  updatefaq,
  deletefaqs,
} from "../actions/faqactions";
import SimpleModal from "../Screens/Modals/Simplemodal";
import AddFAQS from "./AddFAQS";

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: [],
      openFaqPopup: false,
      showupdatebutton: false,
      filterable: false,
      selectAll: false,
    };
  }

  toggleSelection = (selection) => {
    this.setState({ selection });
  };
  toggleSelectAll() {
    let newSelected = {};

    if (this.state.selectAll === 0) {
      this.props.Faqs.forEach((x) => {
        newSelected[x.question] = true;
      });
    }

    this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
    });
  }
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchfaqs());
  }
  closeFaqPopup = () => {
    this.setState({ openFaqPopup: false, selection: [] });
  };
  addFAQ = () => {
    const { dispatch } = this.props;
    const obj = {
      question: "",
      description: "",
    };
    dispatch(emptyfaqform(obj));
    this.setState({ openFaqPopup: true, showupdatebutton: false });
  };
  handleSubmit = () => {
    const { Faqform, dispatch } = this.props;
    if (Faqform.question !== "" && Faqform.description !== "") {
      this.setState({ openFaqPopup: false });
      dispatch(createfaq(Faqform)).then((response) => {
        console.log(response, "response");
        if (response.result) {
          alert("Faq created successfully");
          const obj = {
            question: "",
            description: "",
          };
          dispatch(emptyfaqform(obj));
        } else {
          alert(response.message);
        }
      });
    } else {
      alert("please fill all fields");
      this.setState({ openFaqPopup: true });
    }
  };

  editfaq = (id) => {
    const { dispatch } = this.props;
    dispatch(editfaq(id));
    this.setState({ openFaqPopup: true, showupdatebutton: true });
  };

  updatefaq = () => {
    const { Faqform, dispatch } = this.props;
    const { selection } = this.state;
    dispatch(updatefaq(selection[0], Faqform)).then((response) => {
      alert("updated Faq successfully");
    });
    this.setState({
      openFaqPopup: false,
      showupdatebutton: false,
      selection: [],
    });
  };

  deletefaq = () => {
    const { dispatch } = this.props;
    const { selection } = this.state;
    dispatch(deletefaqs(selection[0])).then((response) => {
      alert("Deleted Faq successfully");
    });
    this.setState({ selection: [] });
  };

  showfilters = () => {
    this.setState({ filterable: true });
  };
  render() {
    const { Faqs } = this.props;
    const { selection, selectAll, openFaqPopup } = this.state;
    const columns = [
      { Header: "Question", accessor: "question" },
      {
        Header: "Description",
        accessor: "description",
        Cell: (row) => <div>{renderHTML(row.original.description)}</div>,
      },
    ];

    return (
      <div className="admin-faq">
        <div className="admin-bredcrum">
          <ul>
            <li>
              <a href="">Home</a>/
            </li>
            <li className="active">
              <a href="">FAQS</a>
            </li>
          </ul>
        </div>
        {/* bredcrum ends here */}

        {/* <div className="admin-title">
          <h1>Add FAQS</h1>
        </div> */}
        <div className="card-design">
          <React.Fragment>
            <div className="add-button">
              <button onClick={this.addFAQ}>
                <i className="fas fa-plus-circle" />
                Add FAQ
              </button>
            </div>
            <i className="fas fa-filter" onClick={this.showfilters}></i>
            {selection.length > 0 ? (
              <div className="admin-action-icons">
                <i
                  className="fas fa-edit"
                  onClick={() => this.editfaq(selection[0])}
                />
                <i
                  className="fas fa-trash-alt"
                  onClick={() => this.deletefaq(selection[0])}
                />
              </div>
            ) : (
              ""
            )}

            <CheckBoxTable
              ref={(r) => (this.checkboxTable = r)}
              data={Faqs}
              columns={columns}
              selection={selection}
              selectAll={selectAll}
              enableMultiSelect={true}
              filterable={this.state.filterable}
              enableSelectAll={true}
              toggleAll={(selectAll, selection) =>
                this.setState({ selectAll, selection })
              }
              toggleSelection={this.toggleSelection}
            />
            <SimpleModal
              show={openFaqPopup}
              handleClose={this.closeFaqPopup}
              handlesubmit={this.handleSubmit}
              bsSize="large"
              showFooter
              header={this.state.showupdatebutton ? "Edit FAQ" : "Create FAQ"}
              showupdatebutton={this.state.showupdatebutton}
              updatefaq={this.updatefaq}
            >
              <AddFAQS />
            </SimpleModal>
          </React.Fragment>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  Faqs: state.FaqReducer.faqs,
  Faqform: state.FaqReducer.faqform,
});

export default connect(mapStateToProps)(Faq);
