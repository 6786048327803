import { TasType } from '../actions/actionType';

const initialState = {
   users:[]
};

export default function TasReducer(state = initialState, action) {
  switch (action.type) {
    case TasType.CREATE_USER: {
      let array = []
      array.push(action.payload.result)
      return {
        ...state,
        users: array,
      };
    }
    default: {
      return state;
    }
  }
}
