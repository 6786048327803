import { API_POST, API_GET, API_PUT } from "./APIMethods";
import { FAQtype } from "./actionType";
import { FaqUrl } from "./actionURL";

export const changefield = obj => dispatch =>
  dispatch({
    type: FAQtype.CHANGE_FAQ_FORM,
    payload: obj
  });

export const createfaq = data => {
  const url = `${FaqUrl.CREATE_FAQ}`;
  return async dispatch => {
    try {
      const result = await API_POST(url, data);
      console.log(result, "result");
      dispatch({
        type: FAQtype.CREATE_FAQ,
        payload: result.result
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchfaqs = () => {
  const url = `${FaqUrl.FETCH_ALLFAQS}`;
  return async dispatch => {
    try {
      const result = await API_GET(url);
      console.log(result, "result");
      dispatch({
        type: FAQtype.FETCH_ALLFAQS,
        payload: result.allfaqs
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const updatefaq = (id, obj) => {
  const url = `${FaqUrl.UPDATE_FAQ}/${id}`;
  return async dispatch => {
    try {
      const result = await API_POST(url, obj);
      console.log(result, "result");
      dispatch({
        type: FAQtype.UPDATE_FAQ,
        payload: { result: result.result, updatefaqid: id }
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const deletefaqs = (id) => {
  const url = `${FaqUrl.DELETE_FAQ}/${id}`;
  return async dispatch => {
    try {
      const result = await API_POST(url);
      console.log(result, "result");
      dispatch({
        type: FAQtype.DELETE_FAQ,
        payload: id
      });
    } catch (error) {
      console.log(error);
    }
  };
};



export const emptyfaqform = obj => dispatch =>
  dispatch({
    type: FAQtype.EMPTY_FAQ_FORM,
    payload: obj
  });

export const editfaq = id => dispatch =>
  dispatch({
    type: FAQtype.SET_FAQ_FORM,
    payload: id
  });
