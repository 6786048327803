import React from "react";
import { Button as AntdButton } from "antd";
import { ArrowUpRight } from "feather-icons-react";

export default function Button({
  label,
  danger = false,
  block = false,
  type,
  disabled = false,
  href,
  icon,
  iconPosition,
  loading,
  shape = "default",
  size = "middle",
  styles,
  className,
  target,
  onClick,
  ghost = false,
}) {
  return (
    <AntdButton
      danger={danger}
      block={block}
      type={type}
      disabled={disabled}
      href={href}
      icon={icon}
      iconPosition={iconPosition}
      loading={loading}
      shape={shape}
      size={size}
      styles={styles}
      className={className}
      target={target}
      onClick={onClick}
      ghost={ghost}
    >
      <span>{label}</span>
    </AntdButton>
  );
}
