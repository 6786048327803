import React from "react";
import { CloseOutlined } from "@ant-design/icons";

class Requestdemoform extends React.Component {
  render() {
    const { closeform, submitform, getdata } = this.props;
    return (
      <div className="call-back-popup">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="popup">
                <h2>Request Demo</h2>
                <div className="cont">
                  <img
                    src={require("../assets/images/request-call-back.svg")}
                    alt="Document IT Request a call back"
                    title="Request a call back"
                  />
                </div>
                <div className="cont">
                  <form>
                    <div className="form-fields">
                      <input
                        type="text"
                        id="name"
                        required
                        placeholder="Full Name"
                        onChange={getdata}
                      />
                    </div>
                    <div className="form-fields">
                      <input
                        type="email"
                        id="email"
                        required
                        placeholder="Your Email ID"
                        onChange={getdata}
                      />
                    </div>
                    <div className="form-fields">
                      <input
                        type="tel"
                        id="mobile"
                        required
                        placeholder="Phone Number"
                        onChange={getdata}
                      />
                    </div>
                    <div className="form-fields">
                      <input
                        type="button"
                        value="Send Request"
                        onClick={submitform}
                      />
                    </div>
                  </form>
                </div>

                {/* <!--Close Button starts here--> */}
                <div className="close-button">
                  <button onClick={closeform}>
                    <CloseOutlined style={{ color: "#fff", fontSize: 13 }} />
                  </button>
                </div>
                {/* <!--Close Button starts here--> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Requestdemoform;
