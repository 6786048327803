import React, { Component } from "react";
import { Link } from "react-router";
import Layout from "../Layout";
import { Row, Col } from "antd";
import JobTitle from "../../Components/JobTitle/JobTitle";

class QaAutomationLead extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <JobTitle
            {...this.props}
            jobdetails={[
              {
                title: "QA Automation Lead (Junior & Seniors)",
                location: "Zurich",
                salary: "120,000 to 140,000 CHF per annum",
              },
            ]}
          />
          {/* <!--Main Slider section ends--> */}

          {/* <!--our solutions starts--> */}
          <div className="job-descriptions">
            <Row justify="center">
              <Col
                xs={{ span: 20 }}
                sm={{ span: 20 }}
                md={{ span: 20 }}
                lg={{ span: 18 }}
              >
                <h2>
                  We are looking for Senior & Junior Automation Engineers to
                  work on the projects.
                </h2>
                <p>
                  The core part of the job is to define, manage and lead a QA
                  work stream comprising of leading a QA team, understanding the
                  underlying solution's technical, functional and non-functional
                  specifications, overall testing plan design for the entire
                  platform, execution, development of test plans, the execution
                  of this testing, quality assurance activities of Functional
                  process, the management of the resolution of bugs and finally
                  delivery of a stable solution.
                </p>
                <div className="profile-data">
                  <h3>Your profile</h3>
                  <ul>
                    <li>
                      Proven track record as a QA Lead within larger project and
                      programs delivery.
                    </li>
                    <li>
                      Hands on Automation skills in implementing and leading
                      automation of Web based application and APIs
                    </li>
                    <li>
                      Experience in digital testing practices (e.g. visual
                      testing tools, cross-browser testing, real device testing
                      etc) and tools (e.g. Azure DevOps)
                    </li>
                    <li>
                      Experience in products testing (e.g. D-365, CRIMS OR T24
                      alike)
                    </li>
                    <li>
                      Exposure to Java, .Net or Python is a value addition
                    </li>
                    <li>
                      Exposure to Cloud, and understand of SaaS, PaaS Services
                      is a value addition
                    </li>
                    <li>
                      Experience in optimizing Functional and Test automation
                      domains
                    </li>
                    <li>
                      Hands-on Testing experience, exposure to Test Management,
                      Functional Testing, Performance Testing, Atlassian based
                      tools etc
                    </li>
                    <li>
                      Strong organizational skills and ability to handle
                      multiple streams supporting delivery and testing practice
                      optimization
                    </li>
                    <li>
                      Previous Financial services domain would be an asset
                    </li>
                    <li>Candidates with valid Permits can apply</li>
                    <li>
                      Dependants with prior work experience and with B work
                      permits can also apply
                    </li>
                    <li>Fluent in English. German would be an advantage.</li>
                  </ul>
                </div>
                <h4>
                  Send your CVs to{" "}
                  <a href="mailto:aline.smith@mcbitss.com">
                    aline.smith@mcbitss.com
                  </a>
                </h4>
              </Col>
            </Row>
          </div>

          {/* achive-business-goal  */}
          <section className="achive-business-goal">
            <Row justify="center">
              <Col
                xs={{ span: 20 }}
                sm={{ span: 20 }}
                md={{ span: 20 }}
                lg={{ span: 18 }}
              >
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <h2>Make the best move to choose your career</h2>
                    <p>find more jobs which suits for you..</p>
                    <Link to="/career" className="btn btn-primary">
                      More Jobs{" "}
                      <i className="flaticon-arrow-pointing-to-right" />
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>
        </Layout>
      </React.Fragment>
    );
  }
}

export default QaAutomationLead;
