import React from "react";
import Layout from "../Layout";
import { Row, Col } from "antd";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import AssetPath from "../../AssetPath/AssetPath";
import { ArrowUpRight } from "feather-icons-react";
import Button from "../../Components/Button/Button";
import ContactCTA from "../Contact/ContactCTA";

class ManagedServices extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <Breadcrumbs
            {...this.props}
            navigatons={[
              {
                classname: "breadcrum corporate-meeting",
                title: "Managed Services",
                description:
                  "Right from Support to Enhancements, count on us to help you maximise your investment",
              },
            ]}
          />
          {/* <!--Main Slider section ends--> */}

          {/* <!--our solutions starts--> */}
          <section className="why-choose-us spacing">
            <div className="container">
              <Row
                justify="middle"
                align="center"
                gutter={[
                  { xs: 0, sm: 15, md: 30, lg: 30 },
                  { xs: 0, sm: 15, md: 30, lg: 30 },
                ]}
              >
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 14 }}
                  lg={{ span: 14 }}
                >
                  <div className="mb-2">
                    <span className="text-uppercase text-primary font-4 d-block mb-2">
                      Document IT Workday Services
                    </span>
                    <h2 className="h3">
                      Managed Services & New Functions Deployment
                    </h2>
                    <p>
                      Right from Support to Enhancements, count on us to help
                      you maximise your investment in P&O (people and
                      organization) IT and business technology through global
                      time zone coverage, 24/7 monitoring, adoption of new
                      functions, enhancements, and flawless delivery.
                    </p>

                    <p>
                      Going live with systems such as Workday is just the first
                      step to making the most of your investment. DocumentIT is
                      here to partner with you to get you where you want to go.
                      Consider DocumentIT experts as an extension of your team,
                      available to support you at your level of need. Key areas
                      of support include configuring business processes, event
                      preparation, optimising functionality, and much more.
                    </p>

                    <p>
                      On going live, you may want to adopt to new functions and
                      scale up the P&O (people and organization) IT and business
                      function. We are here to guide the adoption journey, which
                      can be scalable and achievable with the right timelines
                      and budget.
                    </p>
                    <Button
                      href="/#/contact"
                      type="primary"
                      icon={<ArrowUpRight size={15} />}
                      iconPosition="end"
                      color="primary"
                      label="Consult Us Now"
                    />
                  </div>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 10 }}
                  lg={{ span: 10 }}
                >
                  <img
                    src={AssetPath.images.functionalDeployment}
                    className="border-radius-20 w-100"
                    alt="Managed Services & New Functions Deployment"
                  />
                </Col>
              </Row>
            </div>
          </section>

          <ContactCTA />

          {/* achive-business-goal  */}
          <section className="achive-business-goal">
            <div className="container">
              <Row justify="center" gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <h2>
                    Achieve business goals faster with data science and AI
                  </h2>
                  <p>How we’ve helped clients increase business value…</p>
                  <Button
                    href="/contact"
                    type="primary"
                    icon={<ArrowUpRight size={15} />}
                    iconPosition="end"
                    color="primary"
                    label="Talk to an Expert"
                  />
                </Col>
              </Row>
            </div>
          </section>
        </Layout>
      </React.Fragment>
    );
  }
}

export default ManagedServices;
