import React from "react";
import Layout from "../Layout";
import { Row, Col } from "antd";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import "./BigDataAnalytics.scss";
import dataManagement from "../../assets/images/big-data/icons/data-management.svg";
import dataWarehouse from "../../assets/images/big-data/icons/data-warehouse.svg";
import dataOps from "../../assets/images/big-data/icons/data-ops.svg";
import businessIntelligence from "../../assets/images/big-data/icons/business-intelligence.svg";
import artificialIntelligence from "../../assets/images/big-data/icons/artificial-intelligence.svg";
import dataScience from "../../assets/images/big-data/icons/data-science.svg";
import Button from "../../Components/Button/Button";
import { ArrowUpRight } from "feather-icons-react";

class BigDataAnalytics extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <Breadcrumbs
            {...this.props}
            navigatons={[
              {
                classname: "breadcrum bigdata-analytics",
                title: "Big Data Analytics",
                description:
                  "Unlock business value with Big data analytics, Artificial Intelligence (AI), Machine Learning, Predictive Analytics, and Natural Language Processing (NLP)",
              },
            ]}
          />
          {/* <!--Main Slider section ends--> */}
          <section className="ai-work">
            <h1>Put the power of data science and AI to work</h1>
            <Row justify="center">
              <Col span={20}>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 6 }}
                    lg={{ span: 6 }}
                  >
                    <div className="details">
                      <h3>Attract leads faster</h3>
                      <p>
                        Turn data into actions for segmentation & predictive
                        marketing
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 6 }}
                    lg={{ span: 6 }}
                  >
                    <div className="details">
                      <h3>Accelerate the sales cycle</h3>
                      <p>
                        Identify the best prospects & incentives using data
                        science
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 6 }}
                    lg={{ span: 6 }}
                  >
                    <div className="details">
                      <h3>Increase retention</h3>
                      <p>
                        Uncover pain points and key drivers about why people
                        leave
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 6 }}
                    lg={{ span: 6 }}
                  >
                    <div className="details">
                      <h3>Improve operations</h3>
                      <p>
                        Gain insights to increase efficiency and profitability
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>
          {/* <!--About Document IT section starts--> */}
          <div className="digital-transformation">
            <Row justify="center">
              <Col span={20}>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <div className="heading">
                      <h1>
                        Big Data Analytics Services for Digital Transformation
                      </h1>
                      <p>
                        Make analytical processes faster and more efficient with
                        real-time Big data analytics.
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <div className="card-view">
                      <h4>Architecture Analysis</h4>
                      <ul>
                        <li>
                          Analyzing the app and its needs along with business
                          requirements
                        </li>
                        <li>Consulting on improvements and automation</li>
                        <li>Building a plan for on-prem to cloud migration</li>
                        <li>Designing solutions for a cloud-native app</li>
                        <li>Creating an infrastructure upgrade roadmap</li>
                        <li>Getting an action plan on future improvements.</li>
                      </ul>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <div className="card-view">
                      <h4>Architecture Improvement</h4>
                      <ul>
                        <li>
                          Implementing best practices and automation processes
                          into the existing infrastructure
                        </li>
                        <li>
                          Automating all manual processes with CI/CD pipelines
                          to reduce the cost of operation and minimize human
                          errors
                        </li>
                        <li>
                          Building robust and scalable infrastructure including
                          on-prem integration on demand
                        </li>
                        <li>
                          Implementing serverless solutions to reduce the cost
                          of infrastructure and improve the overall performance
                          of the application.
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <div className="card-view">
                      <h4>Big Data Pipelines</h4>
                      <ul>
                        <li>
                          Designing Big data analytics software solutions based
                          on business requirements
                        </li>
                        <li>
                          Developing, testing, evaluating and maintaining Big
                          data analytics tools Preparing data for analysis
                          including cleaning, data quality checks, and merging
                          data to the single point of analysis
                        </li>
                        <li>
                          Building event-based infrastructure for data
                          processing
                        </li>
                        <li>
                          End-to-end solutions including delivery to required
                          Data Warehouses (including on-prem).
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <div className="card-view">
                      <h4>Data Analysis and Visualization</h4>
                      <ul>
                        <li>
                          Processing the data to the point of analysis with all
                          data points required
                        </li>
                        <li>
                          Preparing reports for stakeholders, including insights
                          and predictions
                        </li>
                        <li>
                          Creating custom algorithms for specific client’s needs
                        </li>
                        <li>Visualizing all reports and insights</li>
                        <li>
                          Creating visual dashboards, including deployments to
                          the client’s side.
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {/* <!--analytics services ends--> */}

          <div className="scaleup-business">
            <Row justify="center">
              <Col span={20}>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <div className="heading">
                      <h1>How We Help Scale Up Your Business</h1>
                      <p>
                        Create data stories and generate transformative insights
                        to be ahead of the competition.
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <div className="card-view">
                      <img src={dataManagement} alt="" />
                      <h4>Data Management</h4>
                      <p>
                        Let our experts show you the power of high-quality data.
                        As a leading Big data analytics company, we guide our
                        clients in maintaining a robust data framework. Our team
                        of engineers has deep expertise in ingesting, storing,
                        and organizing your corporate data through cutting-edge
                        functionality.
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <div className="card-view">
                      <img src={dataWarehouse} alt="" />
                      <h4>Data Warehouse Services</h4>
                      <p>
                        Request our big data analytics consulting services to
                        ensure your data consistency. We assist our clients in a
                        full suite of DWH practices, including implementation,
                        upgrades, and migration of data warehouses. Document IT
                        engineers can also build a full-fledged DWH solution or
                        consult on optimizing current DWH performance.
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <div className="card-view">
                      <img src={dataOps} alt="" />
                      <h4>DataOps for Enterprise Data Management</h4>
                      <p>
                        We merge best agile practices with data analytics to
                        enable your data transformation journey. Our DataOps
                        services allow companies to automate and streamline data
                        flows, unleashing relevant insights. DataOps
                        implementation also renovates the data life cycle, from
                        data retrieval to analysis by creating a unified flow of
                        data.
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <div className="card-view">
                      <img src={businessIntelligence} alt="" />
                      <h4>Business Intelligence and Analytics Solutions</h4>
                      <p>
                        Business Intelligence and analytics services at Document
                        IT help our clients transform data into smart
                        visualizations. We leverage robust BI solutions and
                        build bespoke platforms to amplify the intelligence
                        lifeblood of your business. Make informed decisions with
                        our BI services and handle end-to-end processes from
                        data storage to delivery.
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <div className="card-view">
                      <img src={artificialIntelligence} alt="" />
                      <h4>Artificial Intelligence for Big Data</h4>
                      <p>
                        We link your data ecosystem with intelligent algorithms
                        to unearth actionable insights and automate
                        decision-making. Smart systems also allow you to
                        leverage advanced analytics capabilities like augmented
                        or predictive analytics and process vast stores of data.
                        At Document IT, we build all types of AI functionality -
                        from deep learning models to Robotic Process Automation.
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <div className="card-view">
                      <img src={dataScience} alt="" />
                      <h4>Data Science</h4>
                      <p>
                        Document IT renders data science services with Machine
                        Learning, Artificial Intelligence, and Deep Learning
                        seamlessly baked into it. Our team of expert data
                        scientists creates powerful analytical modules to
                        address your data challenges. Respond more effectively
                        to market changes and customer needs with our data
                        science expertise.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {/* <!--scaleup business--> */}

          <div className="bigdata-benefits">
            <Row justify="center">
              <Col span={20}>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <div className="heading">
                      <h1>
                        Our Data & Analytics Services Help You Reach Your Goals.
                      </h1>
                      <p>
                        Get the most of your data oceans with data engineering
                        and advanced analytics.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={20}>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <div className="benefits">
                      <h4>Enhanced decision-making</h4>
                      <p>
                        Eliminate the tedious guesswork and take informed steps
                        into the future.
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <div className="benefits">
                      <h4>Optimize costs</h4>
                      <p>
                        Real-time forecasting and monitoring for higher ROI and
                        revenues.
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <div className="benefits">
                      <h4>Proactive problem-solving</h4>
                      <p>
                        Identify the potential roots of failures and asset
                        breakdowns in real-time.
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <div className="benefits">
                      <h4>Enhanced customer experience</h4>
                      <p>
                        Generate personalized customer offers based on behavior.
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <div className="benefits">
                      <h4>Advanced analytic capabilities</h4>
                      <p>
                        High-level methods and tools to project future trends.
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <div className="benefits">
                      <h4>Risk mitigation</h4>
                      <p>
                        Minimized risks by optimizing complex decisions at scale
                        and speed.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {/* <!--scaleup business--> */}

          <div className="challenge-we-facing">
            <Row justify="left">
              <Col span={20}>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 21 }}
                    sm={{ span: 21 }}
                    md={{ span: 19 }}
                    lg={{ span: 19 }}
                  >
                    <h2>
                      What Data & Analytics Challenges Is Your Organization
                      Facing?
                    </h2>
                    <p>
                      You try to talk about ​modernizing, but are told “we don’t
                      ​have the data for that.” Every time you ask for new data,
                      it takes too long and costs too much.​ You invest money in
                      reporting, ​and no one is using it.​ You spend more time
                      getting to information than understanding what it can
                      teach us.​
                    </p>
                    <p>Any of this sound familiar?</p>
                    <h4>
                      But building the right ​architecture for the future can
                      ​be daunting for many reasons:
                    </h4>
                    <ul>
                      <li>a continuous wave of novel ​cloud technologies​</li>
                      <li>
                        the competition with predictive ​analytics and
                        artificial intelligence​​
                      </li>
                      <li>
                        a rise of digital and the need ​to shape your customer’s
                        journey​​
                      </li>
                    </ul>
                    <p>
                      The volume and variety of data is only continuing to grow.
                      Protecting your investment in data and analytics demands a
                      holistic, modular architecture.​
                    </p>
                    <Button
                      to="/contact"
                      type="primary"
                      icon={<ArrowUpRight size={15} />}
                      iconPosition="end"
                      color="primary"
                      label="Talk to an Expert"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {/* ends here */}
        </Layout>
      </React.Fragment>
    );
  }
}

export default BigDataAnalytics;
