import React from "react";
import Privacy from "./Privacy";
import Layout from "../Layout";
import { Row, Col } from "antd";

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <div className="breadcrum privacy-policy-img">
            <div className="breadcrum-content">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <h1>Privacy policy</h1>
                    <span>
                      <a href="#/">Home</a>
                      <em className="seperator">|</em> Privacy policy
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--Main Slider section ends--> */}

          {/* <!--Privacy Policy starts--> */}
          <div className="privacy-policy">
            <Row justify="center">
              <Col span={20}>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <Privacy />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {/* <!--Privacy policy ends--> */}
        </Layout>
      </React.Fragment>
    );
  }
}

export default PrivacyPolicy;
