import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Navbar, Nav } from "react-bootstrap";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import "./Navbar.scss";
const menu = (
  <Menu>
    <Menu.Item key="0">
      <a href="#">Logout</a>
    </Menu.Item>
  </Menu>
);
class Navbars extends React.Component {
  logout = () => {
    this.props.router.push("/webadminlogin");
    localStorage.removeItem("admins");
  };
  render() {
    return (
      <div>
        <Navbar bg="light" expand="lg">
          <Navbar.Brand href="#home">
            <img src={require("../../assets/images/logo1.png")} />
          </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Dropdown overlay={menu} trigger={["click"]} arrow>
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  Web Admin <DownOutlined />
                </a>
              </Dropdown>
              {/*<NavDropdown title="Web Admin" id="basic-nav-dropdown">
                <NavDropdown.Item href="#" onClick={this.logout}>Logout</NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const enhancedComponent = connect(mapStateToProps)(withRouter(Navbars));

export default enhancedComponent;
