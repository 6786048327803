import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Row, Col } from "react-bootstrap";
import CheckBoxTable from "./checkboxtable";
import { requestdemobyproduct } from "../actions/requestdemoactions";

const products = [
  { productname: "Aksa Studio", id: 1 },
  { productname: "Dc3", id: 2 },
  { productname: "Reorg Chart Modelling", id: 3 },
];
class requestdemos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "",
      filterable: false,
      selectAll: false,
      selection: [],
      showtable: false,
    };
  }

  toggleSelection = (selection) => {
    this.setState({ selection });
  };
  toggleSelectAll() {
    let newSelected = {};

    if (this.state.selectAll === 0) {
      this.props.Faqs.forEach((x) => {
        newSelected[x.question] = true;
      });
    }

    this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
    });
  }

  handleChange = (selectedOption) => {
    const { dispatch } = this.props;
    const obj = {};
    obj.product = selectedOption.value;
    dispatch(requestdemobyproduct(obj));
    this.setState({ selectedOption, showtable: true });
  };
  render() {
    const { selection, selectAll, selectedOption, showtable } = this.state;
    const { requestdemo } = this.props;
    const Productsoptions = [];
    products.map((prod) => {
      Productsoptions.push({
        label: prod.productname,
        value: prod.productname,
      });
    });
    const columns = [
      { Header: "Name", accessor: "name" },
      {
        Header: "Email",
        accessor: "email",
      },
      { Header: "Mobile", accessor: "mobile" },
      { Header: "Product", accessor: "product" },
    ];
    return (
      <div>
        <h1>Request Demos</h1>
        <div className="card-design">
          <Row>
            <Col lg={2} md={2} sm={2} xs={12}>
              <label>Tools :</label>
            </Col>
            <Col lg={5} md={5}>
              <Select
                value={selectedOption}
                onChange={this.handleChange}
                options={Productsoptions}
              />
            </Col>
          </Row>

          {showtable ? (
            <CheckBoxTable
              ref={(r) => (this.checkboxTable = r)}
              data={requestdemo}
              columns={columns}
              selection={selection}
              selectAll={selectAll}
              enableMultiSelect={true}
              filterable={this.state.filterable}
              enableSelectAll={true}
              toggleAll={(selectAll, selection) =>
                this.setState({ selectAll, selection })
              }
              toggleSelection={this.toggleSelection}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  requestdemo: state.Requestdemo.requestdemobyproduct,
});

export default connect(mapStateToProps)(requestdemos);
