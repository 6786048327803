import React, { Component } from "react";
import { Link } from "react-router";
import Layout from "../Layout";
import { Row, Col } from "antd";
import datamigration from "../../assets/images/services/data-migration.jpg";
import integrationbuild from "../../assets/images/services/integration-build.jpg";
import testing from "../../assets/images/services/testing.jpg";
import dataextratcion from "../../assets/images/services/data-extraction.jpg";
import bigData from "../../assets/images/services/big-data.jpg";
import healthcareImage from "../../assets/images/services/health-care.jpg";
import clientSideDeployment from "../../assets/images/services/client-side-deployment.jpg";
import advisoryServices from "../../assets/images/services/advisory-services.jpg";
import functionalDeployment from "../../assets/images/services/functional-deployment.jpg";
import integrationDeployment from "../../assets/images/services/integration-deployment.jpg";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import Cta from "../Cta/Cta";
import Button from "../../Components/Button/Button";
import { ArrowUpRight } from "feather-icons-react";

class Services extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <Breadcrumbs
            {...this.props}
            navigatons={[
              {
                classname: "breadcrum services",
                title: "Our Services",
                description:
                  "Together we can envision, inspire and transform your business.",
              },
            ]}
          />
          {/* <!--Main Slider section ends--> */}

          {/* <!--our solutions starts--> */}
          <section className="documentit-solution">
            <div className="container">
              <div className="solutions-list">
                <Row justify="left" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                  >
                    <div className="list">
                      <div className="details">
                        <img src={advisoryServices} alt="Advisory Services" />
                        <div className="spec">
                          <h4>Advisory Services </h4>
                          <p className="text-gray text-ellipse3">
                            Expert guidance optimizing Workday for strategic
                            deployment, enhanced performance, and improved user
                            experience.
                          </p>
                          <Button
                            href="/#/advisory-services"
                            type="default"
                            icon={<ArrowUpRight size={15} />}
                            iconPosition="end"
                            color="secondary"
                            label="More Details"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <!--List ends--> */}
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                  >
                    <div className="list">
                      <div className="details">
                        <img
                          src={functionalDeployment}
                          alt="Managed Services and New Functions Deployment"
                        />
                        <div className="spec">
                          <h4>Managed Services and New Functions Deployment</h4>
                          <p className="text-gray text-ellipse3">
                            Comprehensive support for seamless Workday
                            operations, maintenance, and optimization.
                          </p>
                          <Button
                            href="/#/managed-services-and-new-functions-deployment"
                            type="default"
                            icon={<ArrowUpRight size={15} />}
                            iconPosition="end"
                            color="secondary"
                            label="More Details"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <!--List ends--> */}
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                  >
                    <div className="list">
                      <div className="details">
                        <img
                          src={integrationDeployment}
                          alt="Integrations Deployment"
                        />
                        <div className="spec">
                          <h4>Integrations Deployment </h4>
                          <p className="text-gray text-ellipse3">
                            Seamlessly connecting Workday with other systems for
                            streamlined data flow and functionality.
                          </p>
                          <Button
                            href="/#/integration-deployment"
                            type="default"
                            icon={<ArrowUpRight size={15} />}
                            iconPosition="end"
                            color="secondary"
                            label="More Details"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <!--List ends--> */}
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                  >
                    <div className="list">
                      <div className="details">
                        <img
                          src={clientSideDeployment}
                          alt="Client Side Deployment"
                        />
                        <div className="spec">
                          <h4>Client Side Deployment </h4>
                          <p className="text-gray text-ellipse3">
                            Expertly managing Workday implementation on client
                            infrastructure for optimal performance and
                            customization.
                          </p>
                          <Button
                            href="/#/client-side-deployment"
                            type="default"
                            icon={<ArrowUpRight size={15} />}
                            iconPosition="end"
                            color="secondary"
                            label="More Details"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <!--List ends--> */}
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                  >
                    <div className="list">
                      <div className="details">
                        <img
                          src={dataextratcion}
                          alt="Data Extraction and Transformation"
                        />
                        <div className="spec">
                          <h4>Data Extraction and Transformation </h4>
                          <p className="text-gray text-ellipse3">
                            We have in-house build tools to extract data from
                            SAP, pre-configured rules and convert into Workday
                            specific Templates.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <!--List ends--> */}
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                  >
                    <div className="list">
                      <div className="details">
                        <img src={datamigration} alt="Data Migration" />
                        <div className="spec">
                          <h4>Data Migration </h4>
                          <p className="text-gray text-ellipse3">
                            We are expert in data migration from SAP and other
                            sources to Workday. We have knowledge of both SAP
                            and Workday systems.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <!--List ends--> */}
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                  >
                    <div className="list">
                      <div className="details">
                        <img src={integrationbuild} alt="Integration Build" />
                        <div className="spec">
                          <h4>Integration Build</h4>
                          <p className="text-gray text-ellipse3">
                            We have knowledge of all frequently used 3rd party
                            systems including ADP, SDWorx etc.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <!--List ends--> */}
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                  >
                    <div className="list">
                      <div className="details">
                        <img src={testing} alt="Testing" />
                        <div className="spec">
                          <h4>Testing</h4>
                          <p className="text-gray text-ellipse3">
                            We have in-house build tool for automated testing to
                            test workday functionalities after each patch.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <!--List ends--> */}
                  </Col>
                </Row>
              </div>
            </div>
          </section>

          <Cta />
        </Layout>
      </React.Fragment>
    );
  }
}

export default Services;
