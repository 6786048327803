import DEV_CONFIG from './dev';
import PROD_CONFIG from './prod';

let CONFIG = DEV_CONFIG;

if (process.env.NODE_ENV === 'production') {
  CONFIG = PROD_CONFIG;
}

export default CONFIG;
