import React from "react";
import { Link } from "react-router";
import Layout from "../Layout";
import { Row, Col } from "antd";
import "./Tools.scss";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import Cta from "../Cta/Cta";

class Tools extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <Breadcrumbs
            {...this.props}
            navigatons={[
              {
                classname: "breadcrum tools",
                title: "Tools",
              },
            ]}
          />
          {/* <!--Main Slider section ends--> */}

          <section className="tools-inner-section">
            <Row justify="center">
              <Col span={20}>
                <Row justify="center" gutter={25}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <div className="details">
                      <div className="title-description">
                        <h3>Dc3</h3>
                        <p>
                          Collects data from any data source, cleanse with
                          approval workflow and consolidates the data to any
                          target system. This tool is pre-configured with
                          Workday templates.
                        </p>
                        <Link to="/dc3" className="btn">
                          More Details{" "}
                          <i className="flaticon-arrow-pointing-to-right" />
                        </Link>
                      </div>
                    </div>
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <div className="details">
                      <div className="title-description">
                        <h3>AKSA Studio</h3>
                        <p>
                          Automated testing tool for Workday tenants with proven
                          test cases, test scripts management and sensitive data
                          masking functionality.
                        </p>
                        <Link to="/aksa-studio" className="btn">
                          More Details{" "}
                          <i className="flaticon-arrow-pointing-to-right" />
                        </Link>
                      </div>
                    </div>
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <div className="details">
                      <div className="title-description">
                        <h3>Reorg Chart Modelling</h3>
                        <p>
                          Simulate structural changes in your org chart with our
                          reorg features. Our reorg modelling tool helps you
                          plan organizational transformation and model future
                          hierarchies directly in your org chart.
                        </p>
                        <Link to="/reorg-chart-modelling" className="btn">
                          More Details{" "}
                          <i className="flaticon-arrow-pointing-to-right" />
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>

          <Cta />
        </Layout>
      </React.Fragment>
    );
  }
}

export default Tools;
