import React, { Component } from "react";
import { Link } from "react-router";
import Layout from "../Layout";
import { Row, Col } from "antd";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import AssetPath from "../../AssetPath/AssetPath";
import { ArrowUpRight } from "feather-icons-react";
import Button from "../../Components/Button/Button";
import ContactCTA from "../Contact/ContactCTA";

class AdvisoryServices extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <Breadcrumbs
            {...this.props}
            navigatons={[
              {
                classname: "breadcrum why-choose-us",
                title: "Advisory Services",
                description:
                  "Moving your organisation to P&O (people and organization) IT and business transformation is a smart decision",
              },
            ]}
          />
          {/* <!--Main Slider section ends--> */}

          {/* <!--our solutions starts--> */}
          <section className="why-choose-us spacing">
            <div className="container">
              <Row
                justify="middle"
                align="center"
                gutter={[
                  { xs: 0, sm: 15, md: 30, lg: 30 },
                  { xs: 0, sm: 15, md: 30, lg: 30 },
                ]}
              >
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 10 }}
                  lg={{ span: 10 }}
                >
                  <img
                    src={AssetPath.images.workdayConsulting}
                    className="border-radius-20 w-100"
                    alt="Advisory Services"
                  />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 14 }}
                  lg={{ span: 14 }}
                >
                  <div className="mb-2">
                    <span className="text-uppercase text-primary font-4 d-block mb-2">
                      Document IT Workday Services
                    </span>
                    <h2 className="h3">Advisory Services</h2>
                    <p>
                      Moving your organisation to P&O (people and organization)
                      IT and business transformation is a smart decision, but
                      one that usually comes with additional questions.
                      DocumentIT HR and Financials IT experts have a wealth of
                      experience in guiding hundreds of organisations through a
                      transformation journey.
                    </p>

                    <p>
                      The success of the implementation, as well as the
                      subsequent adoption by employees, depends on more than
                      just P&O (people and organization) IT and business systems
                      and specialists. That is why we recommend first examining
                      whether your organisation is ready for the digital
                      transformation you want to undertake.
                    </p>

                    <p>
                      We help the customer to organise short and long-term
                      initiatives into a consistent roadmap for HR
                      Transformation. We help companies to make the best out of
                      the new features, get the most from the systems like
                      Workday platform and integrate the HRIS with enterprise
                      applications/third-party providers.
                    </p>
                    <Button
                      href="/#/contact"
                      type="primary"
                      icon={<ArrowUpRight size={15} />}
                      iconPosition="end"
                      color="primary"
                      label="Consult Now"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </section>

          <ContactCTA />

          {/* achive-business-goal  */}
          <section className="achive-business-goal">
            <div className="container">
              <Row justify="center" gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <h2>
                    Achieve business goals faster with data science and AI
                  </h2>
                  <p>How we’ve helped clients increase business value…</p>
                  <Button
                    href="/#/contact"
                    type="primary"
                    icon={<ArrowUpRight size={15} />}
                    iconPosition="end"
                    color="primary"
                    label="Talk to an Expert"
                  />
                </Col>
              </Row>
            </div>
          </section>
        </Layout>
      </React.Fragment>
    );
  }
}

export default AdvisoryServices;
