import React from "react";
import { connect } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { changefield, createfaq } from "../actions/faqactions";



class AddFAQS extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      converttext: false
    }
  }
  changefield = obj => {
    console.log(obj, "obj");
    const { dispatch } = this.props;
    dispatch(changefield(obj));
  };
  changeQuillField = obj => {
    const { dispatch } = this.props;
    dispatch(changefield(obj));
  };
  createfaqs = () => {
    const { dispatch, Faqs } = this.props;
    dispatch(createfaq(Faqs)).then(response => {
      if (response.result) {
        alert("Faq created successfully");
      } else {
        alert(response.message);
      }
    });
    this.setState({converttext: true})
  };
  render() {
    const { Faqs } = this.props;
    const { converttext } = this.state;
    return (
      <div className="add-faqs">
        <div className="form-fields">
          <label>Title</label>
          <input
          defaultValue ={Faqs.question}
            type="text"
            onChange={e =>
              this.changefield({ key: "question", value: e.target.value })
            }
          />
        </div>
        <div className="form-fields">
          <label>Description</label>
          <ReactQuill
            value={Faqs.description}
            onChange={e =>
              this.changeQuillField({ key: "description", value: e })
            }
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  Faqs: state.FaqReducer.faqform
});

export default connect(mapStateToProps)(AddFAQS);
