import datamigration from "../assets/images/services/data-migration.jpg";
import integrationbuild from "../assets/images/services/integration-build.jpg";
import testing from "../assets/images/services/testing.jpg";
import dataextratcion from "../assets/images/services/data-extraction.jpg";
import bigData from "../assets/images/services/big-data.jpg";
import healthcareImage from "../assets/images/services/health-care.jpg";
import workdayConsulting from "../assets/images/workday-consulting.jpg";
import functionalDeployment from "../assets/images/functional-deployment.jpg";
import integrationDeployemnt from "../assets/images/integration-deployemnt.jpg";
import dc3Data from "../assets/images/dc3-data.jpg";
import clientSideDeployment from "../assets/images/client-side-deployment.jpg";

// why choose us
import whychooseUs from "../assets/images/main-slider/why-choose-us.jpg";

// Team Members
import DarrenTaylor from "../assets/images/team/leadership-team/darren-taylor.jpg";
import Chris from "../assets/images/team/leadership-team/chris-cieslok.png";

// import country
import Germany from "../assets/images/country/germany.png";
import Switzerland from "../assets/images/country/switzerland.png";
import UnitedKingdom from "../assets/images/country/united-kingdom.png";
import India from "../assets/images/country/india.png";

const AssetPath = {
  flag: { Germany, Switzerland, UnitedKingdom, India },
  leadershipTeam: {
    DarrenTaylor,
    Chris,
  },
  images: {
    datamigration,
    integrationbuild,
    testing,
    dataextratcion,
    bigData,
    healthcareImage,
    workdayConsulting,
    functionalDeployment,
    integrationDeployemnt,
    dc3Data,
    clientSideDeployment,
  },
  whychooseUs: {
    whychooseUs,
  },
};

export default AssetPath;
