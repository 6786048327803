import React from "react";
import Layout from "../Layout";
import { Row, Col } from "antd";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import AssetPath from "../../AssetPath/AssetPath";
import Button from "../../Components/Button/Button";
import noImage from "../../assets/images/team/leadership-team/malcolm-page.jpg";
import { ArrowUpRight } from "feather-icons-react";
import "./Team.scss";

const leadershipTeam = [
  {
    name: "Darren Taylor",
    role: "Managing Partner",
    location: "United Kingdom",
    image: [AssetPath.leadershipTeam.DarrenTaylor],
    flag: [AssetPath.flag.UnitedKingdom],
  },
  {
    name: "Christopher Cieslok",
    role: "Managing Partner",
    location: "Germany ",
    image: [AssetPath.leadershipTeam.Chris],
    flag: [AssetPath.flag.Germany],
  },
];

class Team extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <Breadcrumbs
            {...this.props}
            navigatons={[
              {
                classname: "breadcrum why-choose-us",
                title: "Our Team",
                description:
                  "Meet Our Expert Leadership: Driving Innovation and Success with Our Management Team",
              },
            ]}
          />
          {/* <!--Main Slider section ends--> */}

          {/* <!--our solutions starts--> */}
          <section className="our-team spacing">
            <div className="container">
              <h2 className="text-center mb-3">Leadership Team</h2>
              <Row
                gutter={[
                  { xs: 15, sm: 15, md: 15, lg: 15 },
                  { xs: 15, sm: 15, md: 15, lg: 15 },
                ]}
                align="middle"
                className="pb-3 "
              >
                {leadershipTeam.map((member, idx) => (
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 6 }}
                    key={idx}
                  >
                    <div className="team-list" data-aos="fade-up">
                      <img src={member.image || noImage} alt={member.name} />
                      <h3 className="text-black font-weight-semibold mb-1">
                        {member.name}
                      </h3>
                      <div className="text-gray font-6 text-uppercase">
                        {member.role}
                      </div>
                      {member.location && (
                        <div className="location font-6">
                          <img src={member.flag} alt={member.location} />
                          {member.location}
                        </div>
                      )}
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </section>

          {/* achive-business-goal  */}
          <section className="achive-business-goal">
            <div className="container">
              <Row justify="center" gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <h2>Partner with Document IT Consulting and Workday</h2>
                  <p className="mb-2">
                    Partner with Document IT Consulting and Workday to redefine
                    your HR & Finance landscape on a global scale.
                  </p>
                  <p>
                    Take the leap to unmatched efficiency, security, and
                    integration.
                  </p>
                  <Button
                    href="/#/contact"
                    type="primary"
                    icon={<ArrowUpRight size={15} />}
                    iconPosition="end"
                    color="primary"
                    label="Partner with us"
                  />
                </Col>
              </Row>
            </div>
          </section>
        </Layout>
      </React.Fragment>
    );
  }
}

export default Team;
