import React from "react";
import { connect } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import FileBase64 from "react-file-base64";
import {
  changefield,
  createblog,
  updateblog,
  emptyblogform,
} from "../actions/blogactions";

class Blogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
  }

  changefield = (obj) => {
    console.log(obj, "obj");
    const { dispatch } = this.props;
    dispatch(changefield(obj));
  };

  changeQuillField = (obj) => {
    const { dispatch } = this.props;
    dispatch(changefield(obj));
  };

  createblogs = () => {
    const { dispatch, Blog } = this.props;
    const blogform = {
      title: "",
      description: "",
      image: "",
      tag: "",
      category: "",
    };
    if (
      Blog.title !== "" &&
      Blog.description !== "" &&
      Blog.image !== "" &&
      Blog.image !== "" &&
      Blog.category !== ""
    ) {
      dispatch(createblog(Blog)).then((response) => {
        if (!response.error) {
          if (response.result) {
            dispatch(emptyblogform(blogform));
            alert("Blog created successfully");
          } else {
            alert(response.message);
          }
        }
      });
    } else {
      alert("please fill all fields");
    }
  };

  getFiles = (files) => {
    const { dispatch } = this.props;
    console.log(files, "filesss");
    const obj = {};
    obj.key = "image";
    files.map((file) => {
      obj.value = file.base64;
    });
    dispatch(changefield(obj));
    // this.setState({ files: files })
  };

  updatefaq = () => {
    const { Blog, dispatch } = this.props;
    const { selection } = this.state;
    dispatch(updateblog(selection[0], Blog)).then((response) => {
      alert("updated Blog successfully");
    });
    this.setState({
      openFaqPopup: false,
      showupdatebutton: false,
      selection: [],
    });
  };
  render() {
    const { Blog, showupdatebutton } = this.props;
    return (
      <div>
        {showupdatebutton ? (
          ""
        ) : (
          <div className="admin-bredcrum">
            <ul>
              <li>
                <a href="">Home</a>/
              </li>
              <li>
                <a href="">Blogs</a>/
              </li>
              <li className="active">
                <a href="">AddBlogs</a>
              </li>
            </ul>
          </div>
        )}

        {/* bredcrum ends here */}
        {showupdatebutton ? (
          ""
        ) : (
          <div className="admin-title">
            <h1>Add Blogs</h1>
          </div>
        )}
        {/* Title ends here */}
        <div className="card-design">
          <div className="form-fields">
            <label>Title</label>
            <input
              value={Blog.title}
              type="text"
              onChange={(e) =>
                this.changefield({ key: "title", value: e.target.value })
              }
            />
          </div>

          <div className="form-fields">
            <label>Image</label>
            <FileBase64
              multiple={true}
              onDone={this.getFiles}
              maxReceivedMessageSize="10485760"
              value={Blog.image}
            />
          </div>
          {/* <input
          type="text"
          onChange={e =>
            this.changefield({ key: "image", value: e.target.value })
          }
        /> */}
          <div className="form-fields">
            <label>Tag</label>
            <input
              value={Blog.tag}
              type="text"
              onChange={(e) =>
                this.changefield({ key: "tag", value: e.target.value })
              }
            />
          </div>
          <div className="form-fields">
            <label>Category</label>
            <input
              value={Blog.category}
              type="text"
              onChange={(e) =>
                this.changefield({ key: "category", value: e.target.value })
              }
            />
          </div>
          <div className="form-fields full-width">
            <label>Description</label>
            <ReactQuill
              value={Blog.description}
              onChange={(e) =>
                this.changeQuillField({ key: "description", value: e })
              }
            />
          </div>
          {showupdatebutton ? (
            ""
          ) : (
            <div className="form-fields">
              <button onClick={this.createblogs}>Submit</button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  Blog: state.BlogsReducer.blogform,
});

export default connect(mapStateToProps)(Blogs);
