import { cloneDeep} from 'lodash';
import { Requestdemotype } from '../actions/actionType';

const initialState = {
   requestdemos:[],
   requestdemobyproduct: []
};

const createrequestdemos  = (state, action) => {
    const requestdemocloneDeep =[...state.requestdemos]
    requestdemocloneDeep.push(action.payload)
    return {...state, requestdemos:requestdemocloneDeep }
}

export default function RequestdemoReducer(state = initialState, action) {
  switch (action.type) {
    case Requestdemotype.CREATE_REQUEST_DEMO: {
      return createrequestdemos(state, action)
    }
    case Requestdemotype.FETCH_REQUEST_DEMO_BY_PRODUCT: {
        return {...state, requestdemobyproduct:action.payload}
    }
    default: {
      return state;
    }
  }
}
