import React from 'react';

class ErrorPage extends React.Component {
  componentDidMount() {
    this.props.router.push('/webadminlogin');
  }
  render() {
    return null;
  }
}

export default ErrorPage;