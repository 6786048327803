import React from "react";
import Layout from "../Layout";
import { Row, Col } from "antd";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import Button from "../../Components/Button/Button";
import { ArrowUpRight } from "feather-icons-react";

class OurOfferings extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <Breadcrumbs
            {...this.props}
            navigatons={[
              {
                classname: "breadcrum why-choose-us",
                title: "Our Offers",
                description:
                  "Elevate Your HR and Finance Experience with Workday",
              },
            ]}
          />
          {/* <!--Main Slider section ends--> */}

          {/* <!--our solutions starts--> */}
          <section className="spacing">
            <div className="container">
              <Row
                align="center"
                gutter={[
                  { xs: 0, sm: 15, md: 30, lg: 30 },
                  { xs: 0, sm: 15, md: 30, lg: 30 },
                ]}
              >
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <h5 className="mb-3 text-secondary text-center">
                    We cover the full Workday platform: HR, Finance, Workforce
                    and Financial planning, Security and Analytics.
                  </h5>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                >
                  <div className=" our-values border">
                    <h2>Our Offers</h2>
                    <ul>
                      <li>Configuration & Tenant Management</li>
                      <li>Reporting Management</li>
                      <li>Security Management</li>
                      <li>Data Management</li>
                      <li>Integration Management</li>
                      <li>Enhanced Release Management</li>
                      <li>Testing Management</li>
                    </ul>
                  </div>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 16 }}
                  lg={{ span: 16 }}
                >
                  <p>
                    <strong>Configuration & Tenant Management:</strong> Tailored
                    processes from discovery to testing, ensuring seamless
                    business integrations.
                  </p>
                  <p>
                    <strong>Reporting Management:</strong> Comprehensive support
                    for all reporting tiers, from operational essentials to
                    advanced developments including Prism analytics.
                  </p>
                  <p>
                    <strong>Security Management:</strong> Safeguard your data
                    with meticulous role provisioning, ensuring the right access
                    for the right individuals.
                  </p>
                  <p>
                    <strong>Data Management:</strong> Harness the power of
                    Workday tools like Enterprise Interface Builder and Advance
                    Load, for impeccable data transformation and validation.
                  </p>
                  <p>
                    <strong>Integration Management:</strong> Seamlessly link
                    Workday modules with third-party systems, enriching your
                    data resources using Workday Studio and Extend.
                  </p>
                  <p>
                    <strong>Enhanced Release Management:</strong> Stay ahead
                    with evaluations of Workday’s latest releases, understanding
                    their impact, and ensuring smooth rollouts.
                  </p>
                  <p>
                    <strong>Testing Management:</strong> In-depth testing
                    solutions, covering unit, regression, integration, and user
                    acceptance evaluations.
                  </p>
                </Col>
              </Row>
            </div>
          </section>

          {/* achive-business-goal  */}
          <section className="achive-business-goal">
            <div className="container">
              <Row justify="center" gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <h2>Partner with Document IT Consulting and Workday</h2>
                  <p className="mb-2">
                    Partner with Document IT Consulting and Workday to redefine
                    your HR & Finance landscape on a global scale.
                  </p>
                  <p>
                    Take the leap to unmatched efficiency, security, and
                    integration.
                  </p>
                  <Button
                    href="/#/contact"
                    type="primary"
                    icon={<ArrowUpRight size={15} />}
                    iconPosition="end"
                    color="primary"
                    label="Partner with us"
                  />
                </Col>
              </Row>
            </div>
          </section>
        </Layout>
      </React.Fragment>
    );
  }
}

export default OurOfferings;
