import React, { Component } from "react";
import { Link } from "react-router";
import Layout from "../Layout";
import { Row, Col, Descriptions } from "antd";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import AssetPath from "../../AssetPath/AssetPath";
import { ArrowUpRight } from "feather-icons-react";
import Button from "../../Components/Button/Button";
import ContactCTA from "../Contact/ContactCTA";

class ClientSideDeployment extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <Breadcrumbs
            {...this.props}
            navigatons={[
              {
                classname: "breadcrum client-side-deployment",
                title: "Client Side Deployment",
                description: "Take your deployment to the next level.",
              },
            ]}
          />
          {/* <!--Main Slider section ends--> */}

          {/* <!--our solutions starts--> */}
          <section className="why-choose-us spacing">
            <div className="container">
              <Row
                justify="middle"
                align="center"
                gutter={[
                  { xs: 0, sm: 15, md: 30, lg: 30 },
                  { xs: 0, sm: 15, md: 30, lg: 30 },
                ]}
              >
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 14 }}
                  lg={{ span: 14 }}
                >
                  <div className="mb-2">
                    <span className="text-uppercase text-primary font-4 d-block mb-2">
                      Document IT Workday Services
                    </span>
                    <h2 className="h4">Client Side Deployment</h2>
                    <p>
                      We bring a wealth of experience in Project Management,
                      Change Management, Functional and Integration
                      Architecture, Data Leads and Testing during the
                      implementation phase to assist your project team to
                      achieve the timeline and align the budget. Our client-side
                      services offer experienced consultants to leverage your
                      team and get the best project outcome with your deployment
                      partner.
                    </p>
                    <Button
                      href="/#/contact"
                      type="primary"
                      icon={<ArrowUpRight size={15} />}
                      iconPosition="end"
                      color="primary"
                      label="Consult Now"
                    />
                  </div>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 10 }}
                  lg={{ span: 10 }}
                >
                  <img
                    src={AssetPath.images.clientSideDeployment}
                    className="border-radius-20 w-100"
                    alt="Client Side Deployment"
                  />
                </Col>
              </Row>
            </div>
          </section>

          <section className="gray-bg spacing">
            <div className="container">
              <Row
                justify="middle"
                align="center"
                gutter={[
                  { xs: 0, sm: 15, md: 30, lg: 30 },
                  { xs: 0, sm: 15, md: 30, lg: 30 },
                ]}
              >
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 18 }}
                  lg={{ span: 18 }}
                >
                  <h2 className="text-secondary mb-3">
                    Proven to aid in a successful long-term Workday® engagement,
                    our consultants can assist in:
                  </h2>

                  <div className="dotted-card-design bg-white mb-3">
                    <h4>Design and Deployment</h4>
                    <p className="mb-0">
                      Our experienced consultants offer deep Workday® architect
                      level feedback during our client’s Workday® design &
                      deployment process. We help our clients build a strong
                      foundation for life in production and Phase 2 Workday®
                      implementations. Our consultants understand that all of
                      Workday® is connected – you can’t design or implement in a
                      silo, all downstream impacts and cross functional
                      dependencies need to be considered.
                    </p>
                  </div>
                  <div className="dotted-card-design bg-white mb-3">
                    <h4>Training and Knowledge Transfer</h4>
                    <p className="mb-0">
                      We offer extensive training to the end-users on how to
                      handle the system once live – executing transactions,
                      troubleshooting techniques, EIBs, organization
                      maintenance, and general knowledge of how the system was
                      architected. We also offer training for specific
                      workstreams, be it HCM, Absence, Benefits, etc. to teach
                      in-house teams how to support and maintain a functional
                      area.
                    </p>
                  </div>
                  <div className="dotted-card-design bg-white mb-3">
                    <h4>Testing Cycles</h4>
                    <p className="mb-0">
                      We offer full-time and real-time support to develop
                      Workday® testing scripts, execute testing of modules,
                      train the internal teams on Workday® and track cycle
                      progress. Knowing how to test and what impacts to consider
                      is crucial to project success.
                    </p>
                  </div>
                  <div className="dotted-card-design bg-white mb-3">
                    <h4>Reporting</h4>
                    <p className="mb-0">
                      Reporting is an area that rarely gets addressed in the
                      Phase 1 implementation of Workday®. We help our clients
                      build reports dashboards that are ready for go-live. We
                      also help our clients hit the ground running with useful
                      system information and analytics.
                    </p>
                  </div>
                  <div className="dotted-card-design bg-white mb-3">
                    <h4>Go-Live</h4>
                    <p className="mb-0">
                      Our consultants have been through them, countless times.
                      They can help plan, organize, and execute all the
                      activities that need to happen around go-live,
                      additionally starting to prioritize the list of items that
                      were pushed to post go-live.
                    </p>
                  </div>
                  <div className="dotted-card-design bg-white mb-3">
                    <h4>Stabilization</h4>
                    <p className="mb-0">
                      having a dedicated resource through the stabilization
                      period of the go-live helps stabilize any issues (issues
                      are inherent), while providing an opportunity to train the
                      team using real-life examples.Implementation consultants
                      are off to their next project, but our consultants, who
                      have been with you through all the project stages, are
                      always available to you.
                    </p>
                  </div>
                  <div className="dotted-card-design bg-white mb-3">
                    <h4>Determining the Right Support Model</h4>
                    <p className="mb-0">
                      Support in-house or outsource to consultants? Who ‘owns’
                      the updates? How big of a team is needed? Our consultants
                      have seen every different structure of support plan and
                      can help outline what works best for your organization
                      moving forward.
                    </p>
                  </div>
                  <p>
                    A client-side Workday® expert is an asset to any Workday®
                    implementation, providing expertise on methodology and
                    configuration while contributing to end user readiness. The
                    implementation process is long and intense, but a consultant
                    on the team will help ease the burden and drive the project
                    to success.
                  </p>
                </Col>
              </Row>
            </div>
          </section>

          <ContactCTA />

          {/* achive-business-goal  */}
          <section className="achive-business-goal">
            <div className="container">
              <Row justify="center" gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <h2>Get up and running fast.</h2>
                  <p>
                    Once you’ve selected Workday, it’s time to focus on going
                    live.{" "}
                  </p>
                  <p>
                    And with our proven methodology, you get the functionality
                    and value you expect—fast
                  </p>
                  <Button
                    href="/#/contact"
                    type="primary"
                    icon={<ArrowUpRight size={15} />}
                    iconPosition="end"
                    color="primary"
                    label="Talk to an Expert"
                  />
                </Col>
              </Row>
            </div>
          </section>
        </Layout>
      </React.Fragment>
    );
  }
}

export default ClientSideDeployment;
