import { cloneDeep } from "lodash";
import { FAQtype } from "../actions/actionType";

const underscoreId = "_id";
const initialState = {
  faqs: [],
  faqform: {
    question: "",
    description: ""
  }
};

const setfaqform = (state, action) => {
  console.log(action.payload, "action.payload");
  const faqs = cloneDeep(state.faqs);
  const form = cloneDeep(state.faqform);
  faqs.map(faq => {
    if (faq[underscoreId] === action.payload) {
      //eslint-disable-next-line
      (form.description = faq.description), (form.question = faq.question);
    }
    return null;
  });
  return { ...state, faqform: form };
};

const createfaq = (state, action) => {
  console.log(action.payload, "action.payload");
  const faqs = cloneDeep(state.faqs);
  faqs.push(action.payload);
  return { ...state, faqs };
};

const changefaqform = (state, action) => {
  console.log(action.payload, "action.payload");
  const faqform = cloneDeep(state.faqform);
  const key = action.payload.key;
  const value = action.payload.value;
  Object.keys(faqform).map(keys => {
    if (keys === key) {
      faqform[key] = value;
    }
  });
  return { ...state, faqform };
};

const updatefaqform = (state, action) => {
  console.log(action.payload, "action.payload");
  const faqs = cloneDeep(state.faqs);
  faqs.map(faq => {
    if (faq[underscoreId] === action.payload.updatefaqid) {
      //eslint-disable-next-line
      (faq[underscoreId] = action.payload.updatefaqid),
        (faq.description = action.payload.result.description),
        (faq.question = action.payload.result.question);
    } else {
      return faq;
    }
  });
  console.log(faqs);
  return { ...state, faqs };
};

const deletefaqs = (state, action) => {
  console.log(action.payload, "action.payload");
  const faqs = cloneDeep(state.faqs);
  const updatedfaqs = [];
  faqs.map(faq => {
    if (faq[underscoreId] === action.payload) {
      // do nothing
    } else {
      updatedfaqs.push(faq);
    }
    return null;
  });
  return { ...state, faqs: updatedfaqs };
};

export default function FaqReducer(state = initialState, action) {
  switch (action.type) {
    case FAQtype.CHANGE_FAQ_FORM: {
      return changefaqform(state, action);
    }
    case FAQtype.CREATE_FAQ: {
      return createfaq(state, action);
    }
    case FAQtype.FETCH_ALLFAQS: {
      return { ...state, faqs: action.payload };
    }
    case FAQtype.EMPTY_FAQ_FORM: {
      return { ...state, faqform: action.payload };
    }
    case FAQtype.SET_FAQ_FORM: {
      return setfaqform(state, action);
    }
    case FAQtype.UPDATE_FAQ: {
      return updatefaqform(state, action);
    }
    case FAQtype.DELETE_FAQ: {
      return deletefaqs(state, action);
    }
    default: {
      return state;
    }
  }
}
